<template>
    <div>
        <b-overlay
            :show="isLoading"
            rounded
            opacity="0.6"
            spinner-variant="primary"
            spinner-mediam
        >
            <transition
                :name="$store.state.appConfig.layout.routerTransition"
                mode="out-in"
            >
              
              
            </transition>
        </b-overlay>
    </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/useJwt'
export default {
    directives: {
        Ripple,
    },
    mounted() {
        this.getResults()
    },
    data() {
        return {
            tap_id: null,
            isLoading: true,
            isLoadingSave: false
        }
    },
    methods: {
        getResults() {
            useJwt.post('/mhd-orders/tapChargeStatus',{tap_id: this.$route.query.tap_id})
            .then((response) => {
                // console.log(response.data)
                this.$router.push({ name: 'mhd-orders-show', params: {id: response.data.data.id} })
            })
            .catch(response => {
                // console.log(response);
            });
        },
    },
}
</script>

<style lang="sass" scoped>

</style>
